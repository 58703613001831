.cl-template-selection-table {
  tr {
    cursor: pointer;
  }
  &.ui.celled.table tr td {
    &.template-name-cell {
      border-left: none;
    }
  }
}
