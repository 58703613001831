@import (reference) '~semantic-ui/theme/site/globals/site.variables';
@import (reference) '~semantic-ui/theme/site/collections/menu.variables';

.cl-actions-card {
  &.ui.segment {
    margin: 0;
  }
  .header {
    margin: 0;
  }
  .description {
    font-size: 12px;
    margin: 10px 0 20px;
  }
}
