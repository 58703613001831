@import (reference) '~styles/variables';

.modal-content {
  padding: 10px;
}
.label-border {
  .ui.basic.label {
    border: none;
  }
  .ui.primary.button {
    margin-left: 20px;
    background-color: @juniperGreenColor;
    color: rgb(255 255 255);
  }
}
span.clickable:hover {
  cursor: pointer;
}

.ui.striped.table > tr:nth-child(1n):hover, .ui.striped.table tbody tr:nth-child(1n):hover {
  cursor: pointer;
  background: fade(rgb(0 0 0), 3%);
  color: fade(rgb(0 0 0), 95%);
}
