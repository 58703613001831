@import (reference) '~styles/fonts.less';
@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-aos-logo {
  font-family: @coHeadlineFont;
  font-size: 20px;
  display: block;
  text-align: center;

  img {
    width: 29px;
    height: 29px;
    margin: 0 0 13px;
  }

  &.nowrap {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 10px 0 0;
    }
  }
  .trade-mark {
    font-size: 45%;
    top: -1em;
  }
}
