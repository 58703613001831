@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-submit-button {
  &.ui.button {
    background-color: @blue;
    color: @white;
    font-weight: normal;
    &:hover, &:focus {
      background-color: darken(@blue, 5%);
      color: @white;
    }
  }
}
