.cl-topology-view {
  max-width: 1366px;
  margin: 24px auto;

  .caption {
    display: flex;
    align-items: center;
    padding: 20px;
    .header {
      margin: 0;
      display: flex;
      align-items: center;
    }
    .cl-state-label {
      margin-right: 20px;
    }
    .actions {
      display: flex;
      align-items: center;
    }
    .topology-info {
      display: flex;
      align-items: center;
      margin-top: 5px;
      font-size: 12px;
      .title {
        display: inline-block;
        font-weight: bold;
        margin-right: 5px;
      }
      .from-now {
        display: inline-block;
        margin-left: 5px;
      }
      .tag-title {
        margin-top: 10px;
      }
    }
  }
  .ui.button.start-topology-btn {
    margin-right: 20px;
  }
  .warning {
    .content {
      padding: 10px;
    }
  }
  .cl-topology-cards {
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .cl-instructions-guided-labs {
    margin-bottom: 20px;
  }
  .ui.warning {
    margin-left: 20px;
  }
  .cl-instructions-guided-labs, .cl-topology-cards {
    .ui.grid > .row > .column {
      margin-bottom: 10px;
    }
  }
}
