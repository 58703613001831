.cl-tag-selection {
  padding-bottom: 20px;
  margin-top: 20px;
  .caption {
    font-size: large;
    padding: 20px 0;
  }
  .caption-hint {
    font-size: small;
    font-weight: bold;
    font-style: italic;
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    .tag {
      width: 200px;
      padding: 0 10px 20px 0;
    }
  }
}
