@import (reference) '~semantic-ui/theme/site/modules/sidebar.variables';

@sideBarWidth: @wideWidth;

.lab-frame {
    width: 800px;
    .container {
        width: 100%;
        border: none;
    }
}
.sticky-div {
    position: absolute;
    right: 10%;
    top: 10%;
}
.overlay-lab-dimmer {
    --font-size-overlay: 25px;
    .ui.inverted.dimmer .ui.large.loader, .ui.large.loader {
        font-size: var(--font-size-overlay);
    }
     font-size: var(--font-size-overlay);
}
.error-message {
    width: 800px;
}
.cl-main-header.ui.block.header {
    z-index: 0;
}