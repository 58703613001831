.cl-vm-card {
  &.ui.segment {
    margin: 0;
  }
  .copy-btn.ui.button {
    box-shadow: none;
  }
  .copy-btn.ui.button:focus {
    box-shadow: none;
  }
  .copy-btn.ui.button:hover {
    box-shadow: none;
  }
  .header {
    margin: 0;
  }
  .description {
    font-size: 12px;
    margin: 10px 0 20px;
  }
  .open-btn.ui.button {
    margin: 30px 0 0;
  }
  .credentials-label {
    font-weight: bold;
  }
}
