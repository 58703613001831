.cl-topology-reports {
  .header {
    margin: 0 50px 0 0;
  }
  .table-reports {
    display: flex;
    flex-shrink: 1;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 10px;
    .table-report {
      flex: 50%;
      padding: 20px;
    }
  }
}
.align-right {
  margin-top: -10px;
  float: right;
  padding-bottom: 20px;
}
