@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-pagination {
  display: flex;
  align-items: center;
  .current-page {
    margin-right: 40px;
  }
  .ui.pagination.menu {
    min-height: 2em;
    .item {
      padding: 0;
      min-width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      &.active {
        padding: 0;
      }
      &[type="pageItem"] {
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 fade(rgb(0 0 0), 10%);
      }
      &[type="prevItem"] {
        margin-right: 20px;
      }
      &[type="nextItem"] {
        margin-left: 20px;
      }
    }
  }
  .ui.menu {
    border: none;
    box-shadow: none;
  }
  .ui.pagination.menu .icon.item i.icon {
    height: auto;
    color: @lightTextColor;
  }
}
