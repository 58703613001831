.cl-tag-selection {
  .tags {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    .tag {
      width: 200px;
      padding: 0 10px 20px 0;
    }
  }
}
