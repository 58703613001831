@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-credentials-login-form {
  .field {
    label {
      opacity: 0.9;
      font-size: 14px;
    }
    &.cl-password-field {
      margin-bottom: 3px;
    }
  }
}

.forgot-password-button {
  text-align: center;
  border: none;
  background: none;
  background-color: none;
}
