@import (reference) '~semantic-ui/theme/site/modules/sidebar.variables';

@sideBarWidth: @wideWidth;

.cl-main-layout {
  &.pushable {
    height: 100vh;
    &:not(body) {
      transform: none;
      & > .ui.sidebar {
        &.cl-main-sidebar {
          position: fixed;
        }
      }
    }
    .cl-page-outer  {
      position: relative;
      height: 100%;
      width: calc(100% - @sideBarWidth);
      left: @sideBarWidth;
      background-color: rgb(51 51 51);
      overflow: auto;
      .cl-page-inner {
        border-radius: 10px;
        display: block;
        background-color: rgb(255 255 255);
        left: 15px;
        right: 15px;
        margin-top: 15px;
        height: calc(100% - 30px);
        width: calc(100% - 10px);
        overflow: auto;
      }
    }
    & > .pusher.cl-page-content {
      overflow: auto;
    }
  }
  .cl-main-header {
    z-index: 3;
  }
  .cl-view-content {
    padding: 75px 35px 20px;
    &.bottom-padding-big {
      padding-bottom: 100px;
    }
  }
}

@media (width <= 1650px) {
  .cl-main-layout.pushable > .pusher.cl-page-content {
    margin-bottom: 25px;
    overflow-x: scroll;
  }
}
