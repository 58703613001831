@coHeadlineFont: 'Co Headline';
@coHeadlineLightFont: 'Co Headline Light';
@coHeadlineBoldFont: 'Co Headline Bold';

@font-face {
  font-family: @coHeadlineFont;
  src:
    url('fonts/co-headline.otf'),
    url('fonts/co-headline.eot'),
    url('fonts/co-headline.woff');
}

@font-face {
  font-family: @coHeadlineLightFont;
  src:
    url('fonts/co-headline-light.otf'),
    url('fonts/co-headline-light.eot'),
    url('fonts/co-headline-light.woff');
}

@font-face {
  font-family: @coHeadlineBoldFont;
  src:
    url('fonts/co-headline-bold.otf'),
    url('fonts/co-headline-bold.eot'),
    url('fonts/co-headline-bold.woff');
}
