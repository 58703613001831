@import (reference) '~semantic-ui/theme/site/globals/site.variables';

body {
  color: @textColor;
}

.flex-1 {
  flex: 1;
}

.cl-aspect-ratio-container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  &.cl-aspect-ratio-16-9 {
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  &.cl-aspect-ratio-4-3 {
    padding-top: 75%; /* 4:3 Aspect Ratio */
  }
  .cl-aspect-ratio-3-2 {
    padding-top: 66.66%; /* 3:2 Aspect Ratio */
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.cl-caption-padded {
  padding: 20px;
  margin: 0;
}

a:hover {
  color: @darkTextColor;
}

.ui.form {
  .actions {
    display: flex;
    justify-content: flex-end;
  }
}
