@import (reference) '~styles/variables';
@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-topologies-table {
  .status-cell {
    .label {
      margin: 0 5px;
      text-align: center;
      text-transform: uppercase;
    }
    .active {
      background-color: @juniperGreenColor;
      color: @primaryTextColor;
    }
    .inactive {
      background-color: @juniperGreyColor;
      color: @primaryTextColor;
    }
    .in-progress {
      background-color: @juniperTealColor;
      color: @primaryTextColor;
    }
  }
  .actions-cell {
    .icon {
      margin: 0 5px;
    }
  }
  .creation-time-cell, .expiration-time-cell {
    width: 200px;
  }
  .link-color {
    color: rgb(79 135 238);
    background: none;
  }
  .text-small {
    font-size: 12px;
  }
  .topology-name-cell-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      margin: 0;
    }
  }
  .ui.horizontal.list {
    padding: 2px;
  }
}
