.cl-create-topology-view {
  > .caption {
    margin-bottom: 46px;
  }
  > .actions {
    padding-top: 20px;
    .ui.checkbox {
      font-weight: bold;
      margin-left: 20px;
    }
  }
  .section-number {
    font-weight: normal;
    margin-bottom: 32px;
  }
  .tab.ui.segment {
    padding: 0;
    .ui.table {
      border: 0;
    }
  }
  .cl-deployment-region-selection {
    margin-top: 24px;
  }
}
