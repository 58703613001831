.support-wrapper {
  font-size: 13px;
  padding-bottom: 50px;
}
.checkbox-toggle {
  transform: scale(0.8);
  padding-left: 10px;
  float: right;
  bottom: 3px;
}
