@bg-color: white;
@primaryTheme-color:#00b289;
@grey-color:#a3aec2;
@darkGrey-color:#444a59;
@tag-color:#03a9f4;

.step(@color-primary,@color-title,@barBG-color,@border-thickness, @cursor) {
  position: relative;
  color: @color-title;
  text-align: center;
  font-size: 0.875rem;
  display: table-cell;
  z-index: 2;
  .step-title {
    padding-top: 10px;
    font-size: 14px;
    color: @color-title;
    font-weight: 700;
    margin-bottom: 4 px;
  }

  .step-description {
    font-size: 16px;
    color: @color-title;
    font-weight: 700;
    margin-bottom: 4px;
  }

  &:before {
    content: '';
    line-height: 1.9rem;
    display: block;
    margin: 0 auto;
    font-weight: 700;
    font-size: 1rem;
    top: 0;
    left: -20px;
    border-radius: 100%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    background-color: @barBG-color;
    border: @border-thickness solid @color-primary;
    cursor: @cursor;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 1rem;
    z-index: -1;
    left: 50%;
    width: 100%;
    height: 0.125rem;
    background-color: @color-primary;
    cursor: default;
  }

  &:last-child:after {
    display: none;
  }
}
.is-incomplete {
  .step(@grey-color,@grey-color,@grey-color,0, default);
}
.is-current {
  .step(@primaryTheme-color,@darkGrey-color,white,2px, default);
}

.is-completed {
  .step(@primaryTheme-color,@darkGrey-color,@primaryTheme-color,0, pointer);
}
