@import (reference) '~styles/variables';

.nav-buttons {
  .ui.button {
    background-color: @juniperTealColor;
    color: rgb(255 255 255);
  }
  #is-submit {
    &.ui.button {
      background-color: rgb(0 178 137);
      color: rgb(255 255 255);
    }
    &.ui.button:hover {
      background-color: rgb(202 203 205);
      color: rgb(0 0 0);
    }
  }
}
