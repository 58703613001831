@import (reference) '~styles/variables';

.dashboard-redesign {
  .welcome-banner {
    .ui.segment {
      padding: 0%;
      h1 {
        color: @juniperGreenColor;
        font-weight: 700;
        font-size: 26px;
        padding-left: 0;
      }
      h3 {
        margin-top: -10px;
        line-height: 28px;
        font-weight: normal;
        font-size: 18px;
      }
    }
  }
  .table-header-text {
    font-weight: 700;
    font-size: 22px;
  }
  .quick-links-segment {
    float: right;
    width: 350px;
    h2 {
      font-size: 22px;
      font-weight: normal;
    }
    p {
      font-size: 14px;
      text-decoration: underline;
    }
  }
  .juniper-color {
    color: @juniperGreenColor;
  }
  .view-topologies-button {
    padding-top: 20px;
  }
  .ui.tabular.menu + .ui.attached.segment:not(.top) {
    padding: 10px;
  }
  .ui.attached.tabular.menu {
    padding-left: 0;
  }
  .tab-align {
    width: max-content;
    .ui.segment {
      border: none;
      padding: 0;
    }
  }
  .dashboard-table-filter {
    .ui.attached.segment {
      border: none;
      padding: 0;
    }
  }
}
