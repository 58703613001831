.pad-cards {
  padding-top: 20px;
  padding-bottom: 20px;
  a.ui.card > .content, .ui.link.card > .content {
    flex-grow: 0;
    i.small.icon, i.small.icons {
      font-size: 15px;
    }
  }
  .ui.basic.buttons .button, .ui.basic.button {
    margin-top: -10px;
  }
}

.favorited {
  font-weight: bolder;
  padding-bottom: 10px;
}
.favorite-color {
  color: rgb(253 215 86);
}
.feedback-color {
  color: rgb(44 144 187);
}
