@import (reference) '~styles/variables';
@import (reference) '~semantic-ui/theme/site/modules/sidebar.variables';

@sideBarWidth: @wideWidth;

.cl-main-header {
  height: @mainHeaderHeight;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 30px;
  left: 30px;

  &.ui.header {
    margin: 0;
  }
  &.ui.block.header {
    border: 0;
    border-radius: 0;
    padding: 16px;
  }
  .right-content {
    display: flex;
    align-items: center;
  }
  .cl-user-name {
    margin: 0 20px 0 36px;
  }
}
