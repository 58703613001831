.cl-buildservers-view {
  .actions {
    display: flex;
    margin-top: 1em;
    text-align: right;
  }
  .search-form {
    align-items: center;
    .search-input {
      width: 350px;
    }
  }
  .ui.divider {
    margin-bottom: 40px;
  }
  .align-right {
    float: right;
  }
}
