@import (reference) '~styles/variables';
@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-create-topology-user-selector {
  .search-section {
    .create-user {
      padding-top: 15px;
      span {
        padding: 40px;
        font-weight: bold;
      }
      .ui.button {
        background-color: @juniperTealColor;
        color: rgb(255 255 255);
      }
    }
  }
}

@media (width <= 1016px) {
  .search-section {
    flex-wrap: wrap;
  }
}
