.cl-contact-details {
  .ui.table {
    margin: 0 auto;
    border: 0;
  }
  .ui.segment.tab {
    padding: 0;
  }
  .content {
    position: relative;
    .create-topology-btn {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2; // to override tabs
    }
  }
  .cl-pagination {
    padding: 10px;
  }
}
