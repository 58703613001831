@import (reference) '~styles/variables';

.info-content-sidebar {
    &.ui.visible.push.sidebar {
        transform: translate3d(0%, 0, 0);
        background: rgb(51 51 51);
        width: 600px;
    }
    .container {
        width: 100%;
        padding: 20px 0 20px 20px;
        height: max-content;
    }
    .header-text {
        width: 100%;
        font-size: 30px;
        height: 25px;
        font-weight: bold;
        color: @juniperGreenColor;
    }
    .button-collapse {
        float: right;
        margin-top: -10px;
    }
    .label-text {
        font-size: 16px;
        padding-top: 20px;
    }
    .description-text {
        font-size: 12px;
        padding-top: 5px;
    }
    .progress-bar-container {
        padding-top: 20px;
        padding-bottom: 10px;
        .progress-bar-text {
            padding-top: 20px;
            padding-bottom: 10px;
            font-size: larger;
            width: max-content;
        }
        .progress-bar {
            display: grid;
            grid-template-columns: 85% 10%;
            column-gap: 5px;
            width: 100%;
            text-align: center;
            align-content: center;
            .bar {
                grid-column-start: 1;
            }
            .progress-percent {
                margin-top: -5px;
            }
        }
    }
}
.content-card {
    &.ui.cards a.card, .ui.link.cards .card, a.ui.card, .ui.link.card {
        margin-top: 10px;
    }
    .content-font {
        padding: 10px;
        color: rgb(66 66 66);
    }
}

