@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-eula {
  height: 100vh;
  min-height: 400px;
  background: url('~assets/images/website-background.png');
  background-size: cover;
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 1024px;
    max-width: 100vw;
    margin: 0 auto;
    padding: 1rem;
  }

  .cl-aos-logo {
    color: @darkTextColor;
    justify-content: flex-start;
    width: 100%;
  }

  .eula-content-wrapper {
    flex: 1;
    overflow: auto;
  }
}
