@import (reference) '~styles/fonts.less';
@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-login {
  height: 100vh;
  min-height: 750px;
  background: url('~assets/images/website-background.png');
  background-size: cover;

  .cl-aos-logo {
    color: @darkTextColor;
    position: absolute;
    top: 110px;
    text-align: center;
    width: 100%;
  }

  .header {
    font-family: @coHeadlineFont;
    font-weight: normal;
    position: absolute;
    top: 220px;
    width: 100%;
    text-align: center;
    font-size: 41px;
  }

  .forms {
    position: absolute;
    top: 360px;
    width: 380px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}
