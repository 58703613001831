@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-reset-password-view {
  height: 100vh;
  min-height: 550px;
  background: url('~assets/images/website-background.png');
  background-size: cover;
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 1024px;
    max-width: 100vw;
    margin: 0 auto;
    padding: 1rem;
  }
  .cl-aos-logo {
    color: @darkTextColor;
    justify-content: flex-start;
    width: 100%;
  }
  .cl-reset-password-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 100px auto 0;
  }
}
