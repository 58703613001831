/*
 * Copyright 2023-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */

@import url('~semantic-ui-less/themes/default/globals/site.variables');
@import url('../../semantic-ui/theme/site/collections/form.variables');

.cltaginput-container * {
  box-sizing: border-box;
  transition: all 0.1s ease;
}

@gap: 0.5rem;

.cltaginput-container {
  align-items: center;
  background: @inputBackground;
  border: @inputBorder;
  border-radius: @inputBorderRadius;
  display: flex;
  flex-wrap: wrap;
  gap: @gap;
  line-height: 1.4;
  padding: @gap;
}

.cltaginput-container.error {
  border-color: @formErrorBorder;
  color: @formErrorColor;
}

.cltaginput-container:focus-within {
  border-color: @selectedBorderColor;
  box-shadow: @selectedBorderColor;
}

.cltaginput-container.error:focus-within {
  border-color: @formErrorBorder;
  box-shadow: @formErrorBorder;
}

.ui.form input[type='text'].cltaginput-input, input[type='text'].cltaginput-input {
  border: 0;
  outline: 0;
  font-family: @inputFont;
  color: @inputColor;
  font-size: @inputFontSize;

  line-height: inherit;
  width: 50%;

  padding: 0;
}
