.cl-lab-guide-card {
  display: flex;
  flex-direction: column;
  &.ui.segment {
    margin: 0;
  }

  .header {
    margin: 0;
  }
  .description {
    font-size: 12px;
    margin-top: 0;
    padding-bottom: 20px;
  }
  .open-btn.ui.button {
    margin: 10px 0;
  }
}
.milestone-status-message {
  word-wrap: break-word;
  margin-top: 10px;
}
.accordian-header {
  width: 100%;
  .accordian-badge {
    float: inline-end;
  }
}