.cl-create-topology-view {
  > .caption {
    margin-bottom: 46px;
  }
  .section-number {
    padding-top: 20px;
    font-weight: bold;
    margin-bottom: 32px;
  }
  .tab.ui.segment {
    padding: 0;
    .ui.table {
      border: 0;
    }
  }
  .cl-deployment-region-selection {
    margin-top: 24px;
  }
  .template-search {
    padding-top: 30px;
  }
  .nav-buttons-segment {
    position: relative;
    left: 40%;
    .ui.segment {
      height: min-content;
      opacity: 0.8;
      position: fixed;
      bottom: 30px;
      z-index: 10;
      .ui.button {
        opacity: 1;
      }
    }
  }
}

.cl-template-selection-card .card-common-styles {
  padding: 0;
}
// Had to mark this important, since the z-index was being overridden
// by the .hidden.content class of reveal
.ui.slide.reveal > .hidden.content, 
.ui.reveal > .hidden.content {
  z-index: 3 !important;
}
