@background: #fff;
@font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
@foreground-dim: #5d5d5d;
@my-selector: banner;
@green: rgb(57 181 74);
@primary: @green;
@shadow-inner: fade(rgb(0 0 0), 15%);

.sd-page .sd-page__title {
  color: @foreground-dim;
}

.sv-string-viewer {
  font-family: @font-family;
}

.sd-item--checked .sd-item__decorator {
  background: @green;
}

.sd-input:focus {
  box-shadow: 0 0 0 2px @green;
}

.sd-item__control:focus + .sd-item__decorator {
  box-shadow: 0 0 0 2px @green;
  background: var(--background, @background);
  outline: none;
}

.sd-title.sd-container-modern__title {
  box-shadow: 0 2px 0 @green;
}

.sd-header__text {
  h3 {
    color: @foreground-dim;
    font-size: 24px;
    font-weight: bold;
    font-family: @font-family;
  }
}

.sd-btn:not(:disabled):hover {
  background-color: rgb(51 162 66);
}

.sd-btn {
  appearance: none;
  padding: calcSize(2) calcSize(6); /* stylelint-disable-line function-name-case */
  background: @primary;
  box-shadow: 0 1px 2px @shadow-inner;
  border-radius: calcCornerRadius(1); /* stylelint-disable-line function-name-case */
  cursor: pointer;

  font-family: @font-family;
  font-style: normal;
  font-weight: 600;
  font-size: calcFontSize(1); /* stylelint-disable-line function-name-case */
  line-height: calcSize(3); /* stylelint-disable-line function-name-case */
  text-align: center;
  color: @background;
  border: 2px solid transparent;
  outline: none;
}

.blueprint-cards {
  display: block;
}

.sd-html td, .sd-html span, .sd-html div, .sd-html p {
  font-size: 14px;
}

.flow-sizing-container {
  padding: 10px;
}