.text-wrap-card {
  overflow: hidden;
  text-overflow: clip;
  word-wrap: break-word;
}

.ui.grid > .row > [class*="thirteen wide"].column,
.ui.grid > .column.row > [class*="thirteen wide"].column,
.ui.grid > [class*="thirteen wide"].column,
.ui.column.grid > [class*="thirteen wide"].column {
  padding-left: 0;
}

.ui.grid > .row > [class*="three wide"].column,
.ui.grid > .column.row > [class*="three wide"].column,
.ui.grid > [class*="three wide"].column,
.ui.column.grid > [class*="three wide"].column {
  padding-right: 0;
}
