@import (reference) '~styles/variables';

.cl-template-selection-card {
  width: 100%;
  height: 100%;
  align-content: flex-start;
  .ui.four.cards {
    width: 100%;
    padding-bottom: 20px;
  }
  .ui.card {
    position: relative;
    width: 100%;
    max-width: 100px;

    &.focussed-card {
      outline: @juniperGreenColor;
      border: 3px solid @juniperGreenColor;
      box-shadow: none;
      background-color: rgb(255 255 255);
    }
  }
  .ui.card .container {
    display: block;
  }
  img {
    margin-top: 5px;
    max-width: 100%;
  }
  .ui.label {
    margin-top: -20px;
    margin-right: -20px;
    border-bottom-right-radius: 0%;
    border-top-left-radius: 0%;
    &.deployed-label {
      background-color: @juniperTealColor;
      color: rgb(255 255 255);
    }
    &.created-label {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .ui.card:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0 0 0 0%);
  }
  .ui.card:hover:before {
    background-color: fade(rgb(99 134 40), 40%);
    z-index: 3;
  }
  .ui.button {
    background-color: rgb(255 255 255);
    font-weight: bold;
    width: 100%;
    margin-top: 20px;
  }
  .ui.button:focus {
    background-color: rgb(255 255 255);
    font-weight: bold;
  }
  .ui.card .btn-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    align-content: stretch;
    vertical-align: middle;
  }

  .ui.card:hover .btn-container {
    opacity: 1;
    display: block;
    z-index: 3;
  }

  .card-common-styles {
    font-family: Lato, Helvetica, sans-serif;
    padding: 10px;
    vertical-align: top;
    text-align: left;
  }
  .card-header {
    word-break: break-word;
    font-size: 18px;
    color: @juniperGreenColor;
  }
  .card-description-meta {
    font-size: 14px;
  }
  .ui.cards > .card .meta, .ui.card .meta .card-description {
    color: rgb(127 127 127);
  }
  .template-search {
    .description {
      width: 60%;
    }
    .search-bar {
      margin-top: -50px;
    }
  }
}

.align-right {
  margin-top: -10px;
  float: right;
  padding-bottom: 20px;
}

@media (width <= 1070px) {
  .template-search {
    display: block;
    flex-wrap: wrap;
    .description {
      position: relative;
    }
    .search-bar {
      position: relative;
      padding-top: 50px;
      padding-bottom: 20px;
    }
  }
  .ui.four.cards {
    margin-top: 10%;
  }
}
