.charts-div {
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 10px;
  .chart {
    flex: 50%;
    padding: 20px;
  }
}

.icon-padding {
  margin-left: 4px;
  margin-top: -3px;
}

@media (width <= 980px) {
  .charts-div {
    overflow-x: scroll;
  }
}
