div.small {
  font-size: 0.8em;
}

// colors picked with contrast in mind
// see https://developer.chrome.com/blog/devtools-tips-5/
div.red {
  color: rgb(171 31 31);
}

div.yellow {
  color: rgb(107 86 0);
}

div.green {
  color: rgb(1 105 54);
}

div.same-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

div.same-column {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.cl-deployment-region-selection {
  .regions {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    .region {
      :hover {
        cursor: pointer;
      }
      align-items: flex-start;
      width: 200px;
      padding: 0 10px 20px 0;
    }
  }
}
