@import (reference) '~styles/variables';

.cl-create-lab-view {
  > .caption {
    color: @juniperGreenColor;
    margin-bottom: 46px;
  }
  .section-number {
    color: @juniperGreenColor;
    font-size: 20px;
    padding-top: 20px;
    font-weight: normal;
    margin-bottom: 32px;
  }
  .nav-buttons-segment {
    position: relative;
    left: 40%;
    .ui.segment {
      height: min-content;
      opacity: 0.8;
      position: fixed;
      bottom: 30px;
      z-index: 10;
      .ui.button {
        opacity: 1;
      }
    }
  }
}
