.kb-search {
  .ui.basic.buttons .button, .ui.basic.button {
    margin-top: 0;
  }
  padding-right: 0;
}
.query-search {
  padding-left: 0;
  width: 100%;
}
