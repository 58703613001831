@import (reference) '~semantic-ui/theme/site/globals/site.variables';
@import (reference) '~semantic-ui/theme/site/collections/menu.variables';

/** Breadcrumbs */
.ui.breadcrumb {
  display: flex;
  .section {
    font-weight: normal;
    position: relative;
    &.active {
      color: @primaryColor;
      &:hover {
        color: @primaryColor;
      }
    }
    &:hover {
      color: darken(@breadcrumbColor, 20%);
    }
    .loader {
      margin: 0 10px;
    }
  }
}

/** Label */
.ui.primary.labels .label,
.ui.primary.label {
  background-color: @primaryColor !important;
  border-color: @primaryColor !important;
  color: @primaryTextColor !important;
}
.ui.primary.labels .label:hover,
a.ui.primary.label:hover {
  background-color: @primaryColorHover !important;
  border-color: @primaryColorHover !important;
  color: @primaryHoverTextColor !important;
}

.ui.secondary.labels .label,
.ui.secondary.label {
  background-color: @secondaryColor !important;
  border-color: @secondaryColor !important;
  color: @secondaryTextColor !important;
}
.ui.secondary.labels .label:hover,
a.ui.secondary.label:hover {
  background-color: @primaryColorHover !important;
  border-color: @primaryColorHover !important;
  color: @primaryHoverTextColor !important;
}

/** Tabs */
.ui.tabular.menu {
  padding-left: 28px;
  z-index: 1; // to overlap .active.tab
  position: relative;
  border: none;

  .item {
    margin-right: 5px;
    background: @tabularItemBackground;
    border-radius: 5px 5px 0 0;
    &:hover {
      background: @tabularItemBackground;
    }
    &.active:hover {
      background: @white;
    }
  }
  + .ui.segment[class*="bottom attached"] {
    box-shadow: 0 1px 4px 0 fade(rgb(0 0 0), 8%);
    border: 1px solid @tabularBorderColor;
    border-radius: 4px;
  }

  + .ui.attached.segment:not(.top) {
    border-top: 1px solid @tabularBorderColor;
  }
}

/** Icon */
i.primary.icon {
  color: @primaryColor !important;
}

/** Dropdown */
.ui.search.dropdown > input.search {
  color: @textColor;
}
.ui.dropdown:not(.button) > .default.text {
  color: rgb(184 184 184);
}

/** Loader */
.ui.loader.padded {
  margin: 16px auto;
}
