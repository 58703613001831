@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-create-topology-contact-selector {
  &.ui.inline.dropdown {
    color: @primaryColor;
    margin-left: 20px;
    input.search {
      padding-left: 28px;
      min-width: 250px;
    }
    .text {
      padding-left: 28px;
    }
    .visible.menu {
      font-size: 14px;
      min-width: 250px;
    }
    .dropdown.icon {
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
}
