.cl-contacts {
  padding-bottom: 120px;
  .caption {
    padding: 0 0 20px;
  }
  .ui.divider {
    margin: 0 0 40px;
  }
  .pagination-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
