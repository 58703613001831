/*
 * Copyright 2024-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
@import url('styles/variables.less');
@import (reference) '~semantic-ui/theme/site/globals/site.variables';

@borderWidth: 1px;
@border: @borderWidth solid @borderColor;

.editable-input {
  word-wrap: break-word;

  &:hover {
    cursor: text;
    background: @lightGrey;
  }
}

.editable-input-editing {
  textarea {
    resize: none;
    border: @border;
    word-wrap: break-word;
    font: inherit;
    color: inherit;
    overflow: hidden;

    &:active {
      outline-color: @juniperBlueColor;
    }
    &.error {
      background: @errorBackgroundColor;
      outline-color: @errorColor;
    }
  }
}

.editable-input-buttons {
  display: flex;
  justify-content: flex-end;
  .ui.button {
    opacity: 0.8;
  }
}
