@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.Toastify {
  .Toastify__toast-container {
    min-width: max-content;
    width: fit-content;
    word-break: normal;
  }

  .Toastify__toast {
    border-radius: 5px;
    color: @textColor;
    padding: 8px 16px;
    min-height: 56px;

    &.Toastify__toast--info {
      @color: #2185d0;
      @backgroundColor: #dff0ff;

      background-color: @backgroundColor;
      color: @color;
      box-shadow: 0 0 0 1px @color inset, 0 0 0 0 transparent;
    }
    &.Toastify__toast--success {
      @color: #1ebc30;
      @backgroundColor: #e5f9e7;

      background-color: @backgroundColor;
      color: @color;
      box-shadow: 0 0 0 1px @color inset, 0 0 0 0 transparent;
    }
    &.Toastify__toast--warning {
      @color: #b58105;
      @backgroundColor: #fff8db;

      background-color: @backgroundColor;
      color: @color;
      box-shadow: 0 0 0 1px @color inset, 0 0 0 0 transparent;
    }
    &.Toastify__toast--error {
      @color: #db2828;
      @backgroundColor: #ffe8e6;

      background-color: @backgroundColor;
      color: @color;
      box-shadow: 0 0 0 1px @color inset, 0 0 0 0 transparent;
    }

    .Toastify__close-button {
      color: inherit;
    }
  }
}
