@import (reference) '~semantic-ui/theme/site/globals/site.variables';
@import (reference) '~semantic-ui/theme/site/collections/menu.variables';

div.log-row {
  font-family: Consolas, Monaco, 'Bitstream Vera Sans Mono', monospace;

  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

pre.log-row {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;

  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
