.label-text {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .description-text {
    color: rgba(255 255 255 80%);
    margin-bottom: 16px;
  }
  
  .progress-bar-container {
    margin: 16px 0;
  
    .progress-bar-text {
      margin-bottom: 8px;
    }
  
    .progress-bar {
      position: relative;
      display: flex;
      align-items: center;
  
      .bar {
        flex-grow: 1;
        margin: 0 !important;
      }
  
      .progress-percent {
        margin-left: 8px;
        min-width: 45px;
        text-align: right;
      }
    }
  }