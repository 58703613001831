@import (reference) '~semantic-ui/theme/site/globals/site.variables';
@import (reference) '~semantic-ui/theme/site/collections/menu.variables';

.cl-predefined-action-card {
  &.ui.segment {
    margin: 0;
    padding: 0;
  }
  .header {
    display: flex;
    padding: 20px;
    margin: 0;
    .icon {
      position: relative;
      top: 2px;
      font-size: 24px;
    }
    .custom-icon {
      width: 20px;
      height: 18px;
      position: relative;
      top: 4px;
      margin-right: 0.5rem;
    }
  }
  .card-content {
    padding: 15px 20px;
    border-top: 1px solid @tabularBorderColor;
    &.disabled {
      opacity: 0.3;
    }
  }
  .card-children {
    margin-top: 15px;
  }
  .action-content {
    display: flex;
  }
  .action-description {
    flex: 1;
    padding-left: 15px;
    font-size: 12px;
  }
  .action-toggle.ui.toggle.checkbox {
    margin-top: 8px;
    width: 30px;
    label {
      &:before {
        width: 30px;
        height: 16px;
      }
      &:after {
        width: 12px;
        height: 12px;
        top: 2px;
        box-shadow: none;
      }
    }
    input:checked ~ label:after {
      left: 16px;
    }
    input ~ label:after {
      left: 2px;
    }
  }
}
