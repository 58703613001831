.cl-eula-accept-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
  &.ui.form .actions {
    margin-top: 10px;
    justify-content: flex-start;
    .ui.button:last-child {
      margin: 0;
    }
  }
}
