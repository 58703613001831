.cl-topologies-view {
  .actions {
    display: flex;
    margin-top: 1em;
    text-align: right;
  }
  .search-form {
    align-items: center;
    .search-input {
      width: 350px;
    }
  }
  .ui.divider {
    margin-bottom: 40px;
  }
  .ui.floating.dropdown .menu {
    overflow: visible;
  }
}

.confirmContentText {
  word-break: break-word;
  padding: 2em;
}
