.cl-contact-details-caption {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  .contact-name {
    display: flex;
    align-items: center;
    i {
      margin-left: 10px;
    }
  }
}
