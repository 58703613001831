.feedback-link,
.feedback-link:link,
.feedback-link:visited {
  position: fixed;
  background: rgb(0 133 179);
  padding: 5px;
  border: 2px solid rgb(255 255 255);
  border-top: none;
  color: rgb(255 255 255);
  display: block;
  white-space: nowrap;
  text-decoration: none;
  font-size: 15px;
  cursor: default;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  border-radius: 0 0 5px 5px;
}
.feedback-link:hover {
  color: rgb(0 0 0);
  text-decoration: none;
}
.feedback-link.feedback-link-right {
  left: 100%;
  top: 40%;
  transform: rotate(90deg);
  transform-origin: top left;
}
.feedback-helpful-container {
  display: none;
  position: fixed;
  top: 40%;
  right: 2.5em;
  margin: 0;
  padding: 0;
  color: rgb(0 0 0);
  border: 1px solid rgb(208 211 213);
  border-radius: 0.28rem;
  box-shadow: 0 2px 4px 0 fade(rgb(34 36 38), 12%), 0 2px 10px 0 fade(rgb(34 36 38), 15%);
}
.feedback-trigger,
.feedback-trigger:link,
.feedback-trigger:visited {
  color: rgb(0 0 0);
  text-decoration: none;
  text-align: center;
  margin: 10px;
  height: 20px;
  border-radius: 0.25em;
  font-size: 15px;
  white-space: nowrap;
  display: block;
}
.feedback-trigger:hover {
  background: fade(rgb(0 0 0), 5%);
  color: fade(rgb(0 0 0 ), 95%);
  z-index: 13;
  cursor: default;
}
.feedback-title {
  margin: 10px;
  font-size: 15px;
  font-weight: bold;
}
