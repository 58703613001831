.cl-reports-period-selector {
  .calendar-container {
    display: block;
    .calendar-input {
      padding: 10px;
      .calendar-input-margin {
        margin-left: 5px;
      }
      .input-margin {
        margin-left: -5px;
        width: 900px;
      }
    }
    .padding-calendar {
      display: inline-flex;
      align-items: center;
      gap: 10px;
    }
    .end-calendar {
      gap: 15px;
    }
    .button-float {
      float: right;
      position: relative;
      left: -41%;
      margin: 10px 0 0;
      .float-action {
        font-size: medium;
        height: 50px;
        width: 120%;
      }
    }
  }
}

@media (850px <= width <= 1255px) {
  .cl-reports-period-selector {
    .calendar-container {
      .calendar-input {
        .input-margin {
          width: 550px;
        }
      }
      .button-float {
        float: none;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .float-action {
          font-size: medium;
          margin-left: auto;
          margin-right: 0;
          height: 50px;
          width: 250px;
        }
      }
    }
  }
}

@media (760px <= width <= 849px) {
  .cl-reports-period-selector {
    .calendar-container {
      .calendar-input {
        .input-margin {
          width: 350px;
        }
      }
    }
  }
}

.ui.icon.input > input {
  width: 300px;
}
