@import (reference) '~styles/variables';

.cl-manage-template {
  margin-top: -50px;
  .manage-template-form-container {
    max-width: 400px;
  }
  .delete {
    float: right;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    width: 10%;
    .i.icon {
      position: relative;
      float: left;
    }
  }
  .ui.card {
    position: relative;
    width: 50%;
    height: 80%;
    max-width: fit-content;
  }
  .ui.card .container {
    display: block;
    padding: 20px;
  }
  img.ui.image {
    margin-top: 10px;
    max-width: 100%;
  }
  .blueprint-property {
    float: right;
  }
  .deployed-label {
    background-color: rgb(0 150 164) !important;
    color: rgb(255 255 255) !important;
  }
  .ui.label {
    margin-top: -20px;
    margin-right: -20px;
    border-bottom-right-radius: 0%;
    border-top-left-radius: 0%;
  }
  .card-common-styles {
    font-family: Lato, Helvetica, sans-serif;
    padding: 10px;
    vertical-align: top;
    text-align: left;
  }
  .card-header {
    word-break: break-all;
    font-size: 18px;
    color: @juniperGreenColor;
  }
  .card-description-meta {
    font-size: 14px;
  }
  .custom-file-input {
    border: 1px solid rgb(208 208 208);
    border-radius: 2px;
    height: 45px;
    .pretty-file {
      border: 1px solid rgb(124 124 124);
      cursor: pointer;
      display: inline-block;
      padding: 5px 15px;
      margin: 5px;
    }
    .pretty-file input[type="file"] {
      display: none;
    }
  }
}
