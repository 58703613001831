@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-create-contact {
  > .caption {
    display: flex;
    padding: 20px;
    .header {
      margin: 0 20px 0 0;
    }
  }
  .create-contact-form-container {
    max-width: 400px;
  }
}
