@import (reference) '~semantic-ui/theme/site/globals/site.variables';

.cl-auth-form-container {
  width: 367px;
  height: 365px;
  display: flex;
  flex-direction: column;
  opacity: 0.9;
  border-radius: 10px;
  border: solid 1px fade(rgb(255 255 255), 35%);
  background-color: @white;

  .form-title {
    height: 68px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 30px;
  }
  .form-content {
    flex: 1;
    padding: 0 38px;
  }
  .ui.form {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    position: relative;
  }
}
