@iconColor: rgb(255, 255, 255);

.cl-user-name {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 2vh;
  .icon {
    color: @iconColor;
  }
  a, a:hover {
    color: @iconColor;
    position: relative;
    left: 20px;
    max-width: 80%;
    word-wrap: break-word;
  }
  .button-wrapper{
    float: right;
    margin-right: 30px;
    position: relative;
  }
 }
