@import (reference) '~semantic-ui/theme/site/globals/site.variables';
@import (reference) '~semantic-ui/theme/site/collections/menu.variables';

@expandedColor: darken(@invertedBackground, 3%);
@hoverColor: darken(@invertedBackground, 5%);

.cl-main-sidebar {
  color: @white;
  .cl-aos-logo {
    padding: 20px;
    font-size: 12px;
    img {
      position: relative;
      right: 6px;
      width: 19px;
      height: 19px;
    }
  }
  &.ui.vertical.menu > .item {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0;
    font-size: 12px;
    overflow: hidden;

    > .item-label {
      display: block;
      width: 100%;
      height: 100%;
      padding: 14px 14px 18px;
    }
    &.expanded {
      background-color: @expandedColor;
      border-right: 4px solid @primaryColor;
      .sub-menu-divider {
        display: block;
      }
    }
    .item-label {
      cursor: pointer;
      color: @white;
      &:hover {
        background-color: @hoverColor;
      }
    }
    &.highlight > .item-label {
      color: @primaryColor;
    }
    i.icon {
      float: none;
      margin: 0 1em;
    }

    .menu.sub-menu {
      width: 100%;
      margin: 0;
      padding: 10px 0;
      transition: all 0.3s ease-out;

      .item {
        padding: 0;
        .item-label {
          display: block;
          padding: 15px 35px;
          font-size: 12px;
        }
        &.highlight {
          .item-label {
            color: @primaryColor;
          }
        }
      }
    }
    .ui.divider.sub-menu-divider {
      margin: 0;
      width: 100%;
    }
    .sub-menu-divider {
      display: none;
    }
  }
  .ui.checkbox label, .ui.checkbox + label {
    color: @white !important;
  }
}
